<template>
  <button class="yzm-hq" v-if="options.sendOk == false" @click="send">{{textName}}</button>
  <button class="yzm-hq" v-else>{{ options.loadMessage }}</button>
</template>

<script>
import { reactive } from "@vue/reactivity";
import { watch } from '@vue/runtime-core';
export default {
  name: "check-button",
  props: {
    timeout: {
      type: Number,
      default: 60,
    },
    isSend:{
        type:Boolean,
        default:false
    },
    textName:{
      type:String,
      default:'获取验证码'
    }
  },
  emits: ["toggleSend"],
  setup(props, context) {
    const options = reactive({
      sendOk: false,
      loadMessage: "获取验证码",
      loadTime: props.timeout,
      intervalid: null,
    });
    watch(()=>props.isSend,()=>{
        console.log("count改变了");
        options.sendOk=props.isSend;
        if(options.sendOk==true){
            loading();
        }
    })
    function send() {
      context.emit("toggleSend", 1);
    }
    function loading() {
        if (options.intervalid != null) {
          console.log('clear')
          window.clearInterval(options.intervalid);
        }
      options.intervalid = window.setInterval(() => {
        options.loadTime = options.loadTime - 1;
        options.loadMessage="已发送,"+options.loadTime+"秒";
        if (options.loadTime <= 0) {
          options.sendOk = false;
          context.emit("update:isSend", false);
          options.loadTime = props.timeout;
          window.clearInterval(options.intervalid);
        }
      },1000);
    }
    return {
      options,
      send,
    };
  },
};
</script>

<style>
</style>