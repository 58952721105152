<template>
  <div class="footbox">
    <div class="footer">
      <ul>
        <li :class="ac=='index'?'on':''">
          <router-link to="/" class="curr">
            <img v-if="ac=='index'" src="images/f1.png" />
             <img v-else src="images/f01.png" />
            <p>首页</p>
          </router-link>
        </li>
        <li :class="ac=='snap'?'on':''">
          <router-link to="/snap">
            <i v-if="ac=='snap'" style="color:red;height: 1.3rem;border: 0;" class="fa fa-legal"></i>
            <i v-else style="height: 1.3rem;border: 0;"  class="fa fa-legal"></i>
            <p>连续拍</p>
          </router-link>
        </li>
        <li :class="ac=='message'?'on':''">
          <router-link to="/message" class="curr message">
            <img src="images/xqf1.png" />
            <div class="num"  v-if="options.messageCount>0">{{options.messageCount}}</div>
            <p>消息</p>
          </router-link>
        </li>
        <li :class="ac=='my'?'on':''">
          <router-link to="/member">
            <img v-if="ac=='my'" src="images/f4.png" />
            <img v-else src="images/f04.png" />
            <p>我的</p>
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { reactive,computed } from 'vue';
import { useStore } from 'vuex'
export default {
  name: "footer-nva",
  props: {
    ac: {
      type: String,
      default: "index",
    },
  },
  setup(){
     const options=reactive({
       messageCount:0
     });
     const store = useStore();
     options.messageCount=computed(() => store.state.cms.messageCount)
     return {
       options
     } 
  }
};
</script>

<style>
.message .num {
    position: absolute;
    top: 0;
    right: 7.6rem;
    z-index: 99;
    background: #ff2150;
    color: #fff;
    width: 0.9rem;
    height: 0.9rem;
    text-align: center;
    line-height: 0.9rem;
    border-radius: 100%;
}
</style>