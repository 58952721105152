<template>
  <div class="m0myheader">
    <div class="conbox">
      <div class="conboxL">
        <img :src="(options.item.logo==null||options.item.logo=='')?'images/tx.png':options.item.logo.replace('/http://','http://')" class="tt" />
        <div class="btR">
          <p class="p1">
            {{
              options.item.name == "" ? options.item.phone : options.item.name
            }}
          </p>
          <div class="v1">
            <img src="images/mmm.png" />
            <p>我的亲情账号 ></p>
          </div>
        </div>
      </div>
      <div class="conboxR">
        <router-link to="/securitySetting">设置</router-link>
      </div>
      <div class="clear"></div>
    </div>
    <div class="conbox2">
      <ul style="display: none">
        <li>
          <a href="javascript:;">
            <p class="p1">13</p>
            <p class="p2">收藏夹</p>
          </a>
        </li>
        <li>
          <a href="javascript:;">
            <p class="p1">1</p>
            <p class="p2">关注拍品</p>
          </a>
        </li>
        <li>
          <a href="javascript:;">
            <p class="p1">3</p>
            <p class="p2">浏览记录</p>
          </a>
        </li>
      </ul>
    </div>
  </div>
  <div class="clear"></div>
  <div class="mypart1">
    <ul>
      <li>
        <a href="javascript:;">
          <p class="p1">0</p>
          <p class="p2">我的积分</p>
        </a>
      </li>
      <li>
        <router-link to="/message">
          <p class="p1">{{ optionsy.messageCount }}</p>
          <p class="p2">消息</p>
        </router-link>
      </li>
      <li>
        <a href="javascript:;">
          <p class="p1">
            {{
              optionsy.orderCount.submit +
              optionsy.orderCount.distribution +
              optionsy.orderCount.delivery +
              optionsy.orderCount.receivingsuccess
            }}
          </p>
          <p class="p2">订单数量</p>
        </a>
      </li>
      <li>
        <a href="javascript:;">
          <p class="p1">0.00</p>
          <p class="p2">支付订单</p>
        </a>
      </li>
      <li>
        <router-link to="/order">
          <img src="images/my01.png" />
          <p class="p2">消费记录</p>
        </router-link>
      </li>
    </ul>
  </div>
  <div class="kbox"></div>
  <div class="kbox"></div>
  <div class="clear"></div>
  <div class="mypart2">
    <div class="con">
      <div class="pa2_tit">
        <p>买家订单</p>
        <router-link to="/order">查看更多买家订单 ></router-link>
      </div>
      <ul>
        <li>
          <router-link to="/order">
            <div class="ddimg">
              <img src="images/my02.png" />
              <div class="num" v-if="optionsy.orderCount.nopay != 0">
                {{ optionsy.orderCount.nopay }}
              </div>
            </div>
            <p>待付款</p>
          </router-link>
        </li>
        <li>
          <router-link to="/order">
            <div class="ddimg">
              <img src="images/my03.png" />
              <div class="num" v-if="optionsy.orderCount.delivery != 0">
                {{ optionsy.orderCount.delivery }}
              </div>
            </div>
            <p>待发货</p>
          </router-link>
        </li>
        <li>
          <router-link to="/order">
            <div class="ddimg">
              <img src="images/my04.png" />
              <div class="num" v-if="optionsy.orderCount.receiving != 0">
                {{ optionsy.orderCount.receiving }}
              </div>
            </div>
            <p>待收货</p>
          </router-link>
        </li>
        <li>
          <router-link to="/memberRBList">
            <div class="ddimg">
              <img src="images/my06.png" />
              <div class="num" v-if="optionsy.orderCount.refund != 0">
                {{ optionsy.orderCount.refund }}
              </div>
            </div>
            <p>退款/售后</p>
          </router-link>
        </li>
      </ul>
    </div>
  </div>
  <div class="kbox"></div>
  <div class="kbox"></div>
  <div class="clear"></div>
  <div class="mypart2">
    <div class="con">
      <div class="pa2_tit">
        <p>商家订单</p>
        <router-link to="/merchantOrder">查看更多发货订单 ></router-link>
      </div>
      <ul>
        <li>
          <router-link to="/merchantOrder">
            <div class="ddimg">
              <img src="images/my02.png" />
              <div class="num" v-if="optionsy.merchantOrderCount.submit != 0">
                {{ optionsy.merchantOrderCount.submit }}
              </div>
            </div>
            <p>待确认</p>
          </router-link>
        </li>
        <li>
          <router-link to="/merchantOrder">
            <div class="ddimg">
              <img src="images/my03.png" />
              <div class="num" v-if="optionsy.merchantOrderCount.delivery != 0">
                {{ optionsy.merchantOrderCount.delivery }}
              </div>
            </div>
            <p>可发货</p>
          </router-link>
        </li>
        <li>
          <router-link to="/refundBarter">
            <div class="ddimg">
              <img src="images/my06.png" />
              <div class="num" v-if="optionsy.merchantOrderCount.refund != 0">
                {{ optionsy.merchantOrderCount.refund }}
              </div>
            </div>
            <p>售后</p>
          </router-link>
        </li>
      </ul>
    </div>
  </div>
  <div class="clear"></div>
  <div class="kbox"></div>
  <div class="kbox"></div>
  <div class="clear"></div>
  <div class="mypart3">
    <ul>
      <li>
        <a href="javascript:;">
          <img src="images/my11.png" />
          <p>我的活动</p>
        </a>
      </li>
      <li>
        <router-link to="/goodsList">
          <img src="images/ph.png" />
          <p>我的商品</p>
        </router-link>
      </li>
      <li>
        <router-link to="/myAuction">
          <img src="images/lxp.png" />
          <p>我的拍卖</p>
        </router-link>
      </li>
      <li>
        <router-link to="/myShop">
          <img src="images/moneytele.png" />
          <p>我的店铺</p>
        </router-link>
      </li>
      <li>
        <a href="javascript:;" @click="authSend">
          <img src="images/3icon1.png" />
          <p>我的账户</p>
        </a>
      </li>
      <li>
        <router-link to="/addressList">
          <img src="images/my8.png" />
          <p>收获地址</p>
        </router-link>
      </li>
      <li>
        <router-link to="/myPoints">
          <img src="images/typeicon4.png" />
          <p>我的积分</p>
        </router-link>
      </li>
      <li>
        <router-link to="/myBond">
          <img src="images/m_06.png" />
          <p>保证金拍卖</p>
        </router-link>
      </li>
      <li>
        <router-link to="/myRedPacket">
          <img src="images/my7.png" />
          <p>我的红包</p>
        </router-link>
      </li>
      <li>
        <a href="javascript:;"  @click="telCall">
          <img src="images/m_08.png" />
          <p>客服</p>
        </a>
      </li>
      <li>
        <a href="javascript:;" @click="options.isHelp=true">
          <img src="images/help.jpg" />
          <p>帮助</p>
        </a>
      </li>
    </ul>
  </div>
  <div class="clear"></div>
  <div class="kbox"></div>
  <div class="kbox"></div>
  <div class="clear"></div>
  <div class="mypart2" style="display: none">
    <div class="con">
      <div class="pa2_tit">
        <p>天猫超市卡</p>
        <a href="javascript:void()">立刻充值 ></a>
      </div>
      <div class="pa2con">
        <img src="images/my00.jpg" />
        <p>账户余额：￥0.00</p>
      </div>
    </div>
  </div>
  <div class="kbox"></div>
  <div class="kbox"></div>
  <div class="clear"></div>
  <div class="fbox"></div>
  <a href="tel:021-50187716" id="telPhone" ref="telPhone" style="display:none;"></a>
  <footer-nva ac="my"></footer-nva>
  <van-popup
    v-model:show="options.isHelp"
    :style="{ height: '90vh', width: '100%' }"
    round
    position="top"
  >
  <div class="clear"></div>
    <div class="quannewsbox2">
      <h3>一、卖家须知</h3> 
      <b>1、如何注册？ </b>
      <p>卖家要进行账户注册
      ，绑定本人银行储蓄卡或公司账号，用于结算。点击右下角我的→我的账户→根据提示注册（带“*”必填）。注册完成后编辑拍品，完善我的店铺、收货地址等信息就可以发布卖货了。</p>
      <b>2、卖家如何发布拍品？</b>
      <p>点击首页：上传拍品→基础信息（带“*”必填）→填写完成，点击下一步→简介（可复制粘贴）→下一步→选择填写
      属性→下一步，点击添加→首图→确认→完成；点击添加→图片→确认→完成；点击添加→视频（上传不超过10秒）→确认，点击右下角的“完成”。点击“一键拍卖”完成选项，点击发布拍品→发布完成。
      在照片上传过程中如果遇中断，解决方法有两种：一是点击右上角的三个黑点，再点击转弯箭头“↻”刷新即可；
      二是在我的→我的商品→（找到拍品点击）完成→继续操作完成发布。</p>
      <b>3、卖家如何提前准备拍品和发布。</b>
      <p>在首页点击并完成”上传拍品”,点击“一键拍卖”完成“发布拍卖”，保存在“我的”→“我的拍卖”中，发布前可以进行“修改”，点击“发布”选择时间，完成发布。</p>
      <b>4、卖家在“一键拍卖”完成前编辑的拍品信息保存在“我的”→“我的商品”里。</b><br>
      <b>5、卖家如何处理买家未收到货申请退款？</b>
      <p>卖家要不定时查看货品物流情况，及时与买家沟通。在“我的”→“商家订单”→“处理操作”→处理。</p>
      <b>6、卖家如何处理买家收到拍品申请退货？</b>
      <p>卖家要不定时查看货品物流情况。在“我的”→“商家订单”→“售后”→“处理操作”→同意后完成处理。不要点击下方的“确认收货”。</p>
      <b>7、卖家如何确认收到退货？</b>
      <p>点击“我的”→“商家订单”→“售后”→找到退货商品，点击“用户发货中”→“确认收货”。</p>
      <b>8、卖家流拍后如何再上拍：</b>
      <p>点击“我的”→我的拍卖→可重发→复制→确定，在“待发布”中找到拍品，修改→发布。设置保证金的，用微信支付的在“我的拍卖”发布；用余额支付的在此页面点击“我的拍卖”发布。</p>
      <b>9、卖家如何设置“进店逛逛”里的拍品？</b>
      <p>点击“我的”，点击“我的店铺”，点击“拍卖”，点击“推荐”即可。 </p>
      <h3>二、买家须知</h3>
      <b>1、买家如何注册？</b>
      <p>买家参拍要进行账户注册，绑定本人银行储蓄卡或公司账号，用于结算。买家注册后也可以做卖家。点击右下角“我的“→我的账户→根据提示注册（带“*”必填）。注册完成后完善“收货地址”就可以参拍了。再完善“我的店铺”，想卖货的也可以发布拍品了。</p>
      <b>2、买家参拍流程？</b>
      <p>参拍→拍中后在“我的”→“买家订单”中支付订单。（卖家发货）收到货当场开箱验收，有问题联系卖家，达成解决办法，拍照保存证据。在“我的→买家订单”中“确认收货”。收货七天内可申请售后（卖家有特别规定的除外）。</p>
      <b>3、买家如何付款？</b> 
      <p>在“我的”→买家订单→支付订单→操作，提醒卖家。</p>  
      <b>4、买家收货后如何售后全额退款？</b>
      <p>买家收货后，点击“我的”“买家订单”在待收货中找到订单，点击“确认收货”→售后，完成选项提交。</p>
      <b>5、买家在规定时间内没收到拍品如何申请退款?</b>
      <p>点击“我的”“买家订单”在待收货中找到订单并点击订单号（图上方），点击右下角“操作”→“售后”→“完成选项”
      →提交。</p> 
      <h3>三、买卖保证金规定</h3>
      <b>1、为什么要设保证金？</b>
    <p>本着诚实守信原则，卖家可以根据拍品的价值合理设置保证金。对设置保证金的拍卖，卖家和买家都要交纳数额相等的保证金，一方违约保证金补偿对方。</p>
    <b>2、卖家保证金如何规定？</b>
    <p>卖家在发布拍品设置保证金的，在发布的同时要交纳保证金。整个交易完成后保证金退回。买家付款后卖家在规定时间内不发货，则卖家违约，保证金补偿买家。在整个交易过程中因卖家明显过失导致交易失败，卖家保证金补偿买家。</p>
    <b>2、买家保证金如何规定？</b>
    <p>拍中的付款后及时退回；未拍中的拍卖结束后即时退回；未参拍的拍卖结束后即时退回。拍中的在规定时间不付款的算违约，买家保证金补偿给卖家。</p>
      <h3>四、各交易环节时间如何规定？</h3> 
      <p>1、买家中拍后72小时内付款，如果在规定时间内不付款则交易失败，买家违约，设保证金的，买家保证金补偿卖家。</p>
      <p>2、卖家收到发货通知后72天内发货，如果在规定时间内未发货，则交易失败，卖家违约，系统自动退款，卖家保证金补偿买家。</p>
      <p>3、卖家已发货但买家未收到货，买家可申请售后，选择“未收到货”提交等待卖家处理，如果卖家未处理，7天后系统自动退款。</p>
      <p>4、卖家收到退货后要及时处理，完成退货流程，卖家如果不处理，退货物流显示签收7天后自动同意退款。</p>
      <p>5、买家签收七天内可申请无理由退货（特殊商品和卖家特别声明的除外），买家发起申请退货48小时内卖家要确认，如果不确认，48小时后平台将自动同意买家退货申请。</p>
      <p>6、买家申请退款，要及时处理同意退货。卖家不同意退款的，申请平台处理，5个工作日内完成。卖家收到退货后有异议，申请平台处理，5个工作日内完成。</p>
      <h3>五、什么是连续拍？</h3>
      <p>本平台用户都可参加连续拍。本着诚信经营、互利共赢的原则，为活跃交易氛围，让越来越多的用户得到心仪的藏品，平台从委托人处精选藏品参与连续拍，以市场价的十分之一甚至更低的价格开始拍卖，达到提货价后开始提货，不提货的就委托平台代卖，接近限价的拍品就不再参拍，开启快乐消费新模式。</p>
      <h3>六、货款安全吗？</h3> 
      <p>所有货款由独立第三方金融支付牌照公司管理，不用担心货款安全。</p>
      <h3>七、如何提货款？</h3>  
      <p>点击“我的”、“我的账户”、</p>
      <p>“刷新”、“提现”，输入提款数额，点击“提现”，资金将在下一个工作日到账。</p>
      <h3>八、商家是个人或公司如何注册绑定账号？</h3>
      <p>商家以个人名义注册绑定个人银行储蓄卡用于结算。以公司名义注册绑定公司账号用于结算。</p>
      <h3>九、什么是“7天无理由退货”？</h3> 
      <p>根据买家选购的商品属性及与商家的约定，在满足7天无理由退货条件的前提下，将支持“7天无理由退货”；您可以在签收之日起7个自然日内，在订单页面根据提示完成退货退款申请。</p>
      <h3>十、平台对用户如何收费？</h3>
      <p>为了让利给用户，成交后平台仅收取卖家3%服务费（佣金），没有其他费用。</p>
    </div>
  </van-popup>
</template>

<script>
import FooterNva from "@/components/FooterNva.vue";
import memberModel from "@/model/memberModel";
import { useStore } from "vuex";
import { reactive, computed } from "vue";
import { Dialog } from 'vant';
import stringUtils from "@/utils/stringUtils";
import config from "@/config/configUtils";
export default {
  components: { FooterNva },
  name: "memberPage",
  setup() {
    const { logout, isLogin, options } = memberModel();
    const optionsy = reactive({
      orderCount: {
        submit: 0,
        distribution: 0,
        delivery: 0,
        receiving: 0,
        receivingsuccess: 0,
        nopay: 0,
        total: 0,
      },
      merchantOrderCount: {
        submit: 0,
        distribution: 0,
        delivery: 0,
        receiving: 0,
        receivingsuccess: 0,
      },
      messageCount: 0,
      isHelp:false
    });
    isLogin();
    const store = useStore();
    optionsy.orderCount = computed(() => store.state.common.orderCount);
    optionsy.merchantOrderCount = computed(
      () => store.state.common.merchantOrderCount
    );
    optionsy.messageCount = computed(() => store.state.cms.messageCount);
    const authSend = () => {
      stringUtils.redirectForm(config.authUrl, [], "post");
    };
    const telCall=()=>{
      Dialog.alert({
          theme:'round-button',
          title: '客户服务',
          message: '咨询售后联系我们 电话:021-50187716',
        }).then(() => {
          // on close
          var a=document.getElementById('telPhone');
          a.click();
        });
    }
    return {
      logout,
      options,
      optionsy,
      authSend,
      telCall
    };
  },
};
</script>

<style>
.quannewsbox2 p{
  text-indent:1rem;
  line-height: 1.1rem;
  margin-bottom: 1.1rem;
}
.quannewsbox2 b{
  line-height: 1.2rem;
}
.quannewsbox2 h3{
  font-size: 1rem;
  margin-bottom: 1rem;
}
</style>