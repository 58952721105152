<template>
  <div class="headerbox">
    <div class="header">
      <div class="headerL">
        <a onclick="javascript:history.back(-1)" class="goback"
          ><img src="images/goback.png"
        /></a>
      </div>
      <div class="headerC">
        <p>积分</p>
      </div>
      <div class="headerR"></div>
    </div>
  </div>
  <div class="clear"></div>
  <div class="hbox"></div>
  <div class="jfheader">
    <div class="guize">积分使用规则</div>
    <div class="jfnum">{{ options.myPoints.points }}<span>个</span></div>
    <div class="jfsub">小积分，有大用，多领一些屯起来！</div>
    <div class="jfgl">
      <ul>
        <li style="width: 49%" @click="setType('his')">
          <a href="javascript:;">收入</a>
        </li>
        <li style="width: 49%; border-right: 0px" @click="setType('pay')">
          <a href="javascript:;">支出</a>
        </li>
      </ul>
    </div>
  </div>
  <div class="clear"></div>
  <div class="kbox"></div>
  <div class="jfbox">
    <van-pull-refresh v-model="options.refreshing" @refresh="onRefresh">
      <van-list
        v-model:loading="options.loading"
        :finished="options.finished"
        :offset="1000"
        finished-text="没有更多了"
        @load="onLoad"
      >
        <div class="jfbox1" v-for="h in options.his" :key="h.id">
          <div class="jfbox1_L" v-if="h.pointsCode == 'MemberSign'">
            <img src="images/typeicon5.png" style="object-fit: cover" />
          </div>
          <div class="jfbox1_L" v-else>
            <img
              src="images/typeicon4.png"
              v-if="h.bodyImg == null || h.bodyImg == ''"
              style="object-fit: cover"
            />
            <img :src="h.bodyImg" v-else style="object-fit: cover" />
          </div>
          <div class="jfbox1_R">
            <div class="jfbox1_R1">
              <div class="v1" v-if="h.bodyTitle != null && h.bodyTitle != ''">
                圣多明尼2015冬季冬装新款女装韩版宽...
              </div>
              <div class="v2">
                {{ stringUtils.dateFormat(h.createTime, "yyyy-MM-DD HH:mm") }}
              </div>
            </div>
            <div class="jfbox1_R2" v-if="options.searchParams.type=='his'">
              <div class="v3">{{ h.pointsTitle }}送积分</div>
              <div class="v4">+{{ h.points }}</div>
            </div>
            <div class="jfbox1_R2" v-else>
              <div class="v3">积分兑换</div>
              <div class="v4">-{{ h.points }}</div>
            </div>
          </div>
          <div class="clear"></div>
        </div>
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
import pointsApi from "@/api/pointsApi";
import { reactive } from "@vue/reactivity";
export default {
  setup() {
    const options = reactive({
      his: [],
      pageCount: 1,
      pageNo: 1,
      refreshing: false,
      loading: false,
      finished: false,
      total:0,
      myPoints: {
        points: 0,
      },
      searchParams: {
        draw: 1,
        pageNo: 0,
        length: 10,
        type: "his",
      },
    });
    const setType=(val)=>{
      options.searchParams.type=val;
      onRefresh();
    };
    const onRefresh= async ()=>{
        options.finished = false;
        options.loading = true;
        options.refreshing = true;
        onLoad();
      }
    const onLoad = async () => {
      if (options.refreshing) {
        options.his = [];
        options.pageCount = 1;
        options.pageNo=1;
        options.refreshing = false;
      }else{
        if (options.pageNo < options.pageCount) {
          options.pageNo = options.pageNo + 1;
        }
      }
      options.searchParams.pageNo = (options.pageNo - 1);
      if (
        options.his.length > 0 &&
        options.total > 0 &&
        options.his.length >= options.total
      ) {
        options.finished = true;
        return;
      }
      pointsApi.getMyHis(options.searchParams).then((res) => {
        if (res.status == 200) {
          options.total = res.recordsTotal;
          let count = Math.ceil(res.recordsTotal / options.searchParams.length);
          options.pageCount = count;
          res.data.forEach((item) => {
            if (options.his.length < res.recordsTotal) {
              options.his.push(item);
            }
          });
        }
          options.loading = false;
          if (options.his.length >= options.total) {
            options.finished = true;
          }
      });
    };
    // const getMyPay = () => {
    //   pointsApi.getMyPay().then((res) => {
    //     if (res.status == 200) {
    //       options.his = res.data;
    //     }
    //   });
    // };
    const getMy = () => {
      pointsApi.getMyPoints().then((res) => {
        if (res.status == 200) {
          options.myPoints = res.result;
        }
      });
    };
    getMy();
    onRefresh();
    return {
      options,
      onLoad,
      onRefresh,
      setType,
    };
  },
};
</script>

<style>
</style>