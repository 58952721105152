<template>
  <div class="headerbox">
    <div class="header">
      <div class="headerL">
        <a onclick="javascript:history.back(-1)" class="goback"
          ><img src="images/goback.png"
        /></a>
      </div>
      <div class="headerC">
        <p>我的保证金</p>
      </div>
      <div class="headerR"></div>
    </div>
  </div>
  <div class="clear"></div>
  <div class="hbox"></div>
  <div class="jfheader">
    <div class="guize">保证金规则</div>
    <div class="jfsub">保证金保证拍卖双方利益！</div>
    <div class="jfgl">
      <ul>
        <li :class="options.searchParams.type==0?'st':''">
          <a href="javascript:;" @click="setType(0)">我的参拍</a>
        </li>
        <li :class="options.searchParams.type==1?'st':''">
          <a href="javascript:;" @click="setType(1)">我的拍卖</a>
        </li>
      </ul>
    </div>
  </div>
  <div class="clear"></div>
  <div class="kbox"></div>
  <div class="jfbox" >
    <van-pull-refresh v-model="options.refreshing" @refresh="onRefresh">
      <van-list
        v-model:loading="options.loading"
        :finished="options.finished"
        :offset="200"
        finished-text="没有更多了"
        @load="onLoad"
      >
    <div class="jfbox1" v-for="b in options.data" :key="b">
      <div class="jfbox1_L" v-if="b.isOnline==1" @click="toAuction(b.id)" >
        <img :src="b.pictureSmall" style="object-fit: cover" />
      </div>
      <div class="jfbox1_L" v-else  >
        <img :src="b.pictureSmall" style="object-fit: cover" />
      </div>
      <div class="jfbox1_R">
        <div class="jfbox1_R1">
          <div class="v1" style="width: 30%;">{{b.title}}</div>
          <div class="v2" style="width: 50%;" v-if="b.isOnline==1">
            <vab-count-down 
            :startTime="b.startTime"
             :endTime="b.endTime"
            ></vab-count-down>
          </div>
          <div class="v2" v-else>
            <router-link class="but" to="/myAuction">发布</router-link>
          </div>
        </div>
        <div class="jfbox1_R2">
          <div class="v3">保证金额</div>
          <div class="v4">￥{{stringUtils.fixedFormat(b.amount)}}</div>
        </div>
      </div>
      <div class="clear"></div>
    </div>
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
import { reactive } from "@vue/reactivity";
import { useRouter } from "vue-router";
import auctionApi from "@/api/auctionApi";
import VabCountDown from '@/components/VabCountDown.vue';
export default {
  components: { VabCountDown },
  setup() {
    const options = reactive({
      data: [],
      pageCount: 1,
      pageNo: 1,
      refreshing: false,
      loading: false,
      finished: false,
      total:0,
      searchParams: {
        draw: 1,
        start: 0,
        length: 10,
        type: 0,
      },
    });
    const router = useRouter();
    const onRefresh= async ()=>{
        options.finished = false;
        options.loading = true;
        options.refreshing = true;
        onLoad();
      }
    const onLoad = async () => {
      if (options.refreshing) {
        options.data = [];
        options.pageCount = 1;
        options.pageNo=1;
        options.refreshing = false;
      }else{
        if (options.pageNo < options.pageCount) {
          options.pageNo = options.pageNo + 1;
        }
      }
      options.searchParams.start = (options.pageNo - 1) * options.searchParams.length;
      console.log("start1:" + options.searchParams.start);
      options.searchParams.start = options.searchParams.start < 0 ? 0 : options.searchParams.start;
      if (
        options.data.length > 0 &&
        options.total > 0 &&
        options.data.length >= options.total
      ) {
        options.finished = true;
        return;
      }
     await auctionApi.getMyBond(options.searchParams).then((res) => {
      if (res.status == 200) {
          options.total = res.recordsTotal;
          let count = Math.ceil(res.recordsTotal / options.searchParams.length);
          options.pageCount = count;
          res.data.forEach((item) => {
            if (options.data.length < res.recordsTotal) {
              options.data.push(item);
            }
          });
        }
          options.loading = false;
          if (options.data.length >= options.total) {
            options.finished = true;
          }
      });
    };
    const setType=(type)=>{
        options.searchParams.type=type;
        onRefresh();
    }
    const toAuction=(id)=>{
      router.push({ path:"/auctionDetail/"+id});
    }
    onRefresh();
    return {
      options,
      setType,
      toAuction,
      onRefresh,
      onLoad
    };
  },
};
</script>

<style>
.jfbox1_R1 .but {
    display: block;
    border: 1px solid #ff2150;
    color: #fff;
    font-size: 0.75rem;
    padding: 0 0.5rem;
    height: 1.4rem;
    line-height: 1.4rem;
    border-radius: 3px;
    background: #ff2150;
    box-sizing: border-box;
}
.jfbox1_R .jfbox1_R1 .v2 span {
  width: 100% !important;
}
.jfgl .st a{
    color:#e3d528 !important;
}
</style>