import config from '@/config/configUtils'
import ajaxHandle from '@/utils/ajaxHandle'

const baseurl = config.basepath+'cp/';
const pointsApi = {
    getMyPoints: () => {
        return ajaxHandle(baseurl, 'post', 'api/getMy',  {})
    },
    getMyHis: (params) => {
        return ajaxHandle(baseurl, 'post', 'api/getMyHis',  params)
    },
    getMyPay:(params)=>{
        return ajaxHandle(baseurl, 'post', 'api/getMyPay', params) 
    },
    searchGoods:(params)=>{
        return ajaxHandle(baseurl, 'post', 'public/goods/search', params) 
    },
    getGoods:(params)=>{
        return ajaxHandle(baseurl, 'post', 'public/goods/get', params) 
    },
    payGoods:(params)=>{
        return ajaxHandle(baseurl, 'post', 'api/pay', params) 
    },
}

export default pointsApi