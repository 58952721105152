import { reactive } from "vue";
import fundApi from "@/api/fundApi";
import { Toast } from "vant";
const bankModel=()=>{
    const bankOptions=reactive({
        banks:[],
        isShow:false,
        cascaderValue:"",
    })
    const getAll=()=>{
        fundApi.getAllBank().then((res) => {
            if (res.status == 200) {
                bankOptions.banks=res.result;
            } else {
              Toast.fail(res.message);
            }
          });
    }
    getAll();
    return {
        bankOptions,
    }
}

export default bankModel;