import { reactive, computed } from 'vue';
import memberApi from '@/api/memberApi'
import filesApi from "@/api/filesApi";
import { useStore } from 'vuex'
import cookies from 'js-cookie';
import config from '@/config/configUtils'
import { Toast } from "vant";
const memberModel = () => {
    const options = reactive({
        item: {
            sex: 1,
            name: '',
            phone: '',
            logo: '',
            birthday: '',
        },
        isNickNameShow:false,
        isPhoneShow:false,
        isLogoShow:false,
        loginSrc: 'pc'
    })
    const paramsuPw = reactive({
        oldPwd: '',
        newPwd: '',
        confirmps: ''
    })
    const paramsUpdate=reactive({
        phone:'',
        name:'',
        logo:''
    });
    const store = useStore();
    options.loginSrc = computed(() => store.getters.getLoginSrc);
    function getInfo() {
        memberApi.getInfo({ loginSrc: options.loginSrc }).then(res => {
            if (res.status == 200) {
                options.item = res.result;
            }
        });
    }
    options.token = computed(() => store.getters.getToken);
    function isLogin() {
        console.log('token:' + options.token)
        if (options.token == '') {
            window.location = config.loginUrl;
        } else {
            getInfo();
            store.commit('setOrderCount');
            store.commit('setRefund');
        }
    }
    function logout() {
        Toast.loading({
            forbidClick: true,
            message: "退出中......",
        });
        memberApi.logout().then(res => {
            if (res.status == 200) {
                cookies.remove('member-info', { path: '/', domain: ".lecuiyipai.com", });
                cookies.remove('member-token', { path: '/', domain: ".lecuiyipai.com", });
                cookies.remove('loginSrc', { path: '/', domain: ".lecuiyipai.com", });
                setTimeout(() => {
                    Toast.clear();
                    window.location = config.indexUrl;
                }, 1000)
            }
        });
    }
    function updatePwd() {
        Toast.loading({
            forbidClick: true,
            message: "处理中......",
        });
        memberApi.updatePwd(paramsuPw).then(res => {
            Toast.clear();
            if (res.status == 200) {
                console.log('密码修改成功')
                Toast.success('密码修改成功');
            }else{
                Toast.fail(res.message); 
            }
        });
    }
    const updateNickName=()=>{
        Toast.loading({
            forbidClick: true,
            message: "处理中......",
        });
        memberApi.updateNickName({name:paramsUpdate.name}).then(res=>{
            Toast.clear();
            options.isNickNameShow=false;
            if(res.status==200){
                getInfo();
                Toast.success('会员名修改成功');
            }else{
                Toast.fail(res.message); 
            }
        })
    }
    const updatePhone=()=>{
        if(paramsUpdate.phone!=''){
            if (!/^1[3456789]\d{9}$/.test(paramsUpdate.phone)) {
                Toast.fail("手机号格式错误");
                return;
              }
        }else{
            return;
        }
        
        options.isPhoneShow = false
        Toast.loading({
            forbidClick: true,
            message: "处理中......",
        });
        memberApi.updatePhone({name:paramsUpdate.phone}).then(res=>{
            Toast.clear();
            if(res.status==200){
                getInfo();
                Toast.success('手机修改成功');
            }else{
                Toast.fail(res.message); 
            }
        })
    }
    const updateLogo=(file)=>{
        Toast.loading({
            forbidClick: true,
            message: "处理中......",
        });
        filesApi.uploadImg({ type: "logo", upfile: file.file }).then((res) => {
            Toast.clear();
            if (res.status == 200) {
                paramsUpdate.logo=res.result.url  
            }else{
                Toast.fail(res.message); 
            }
          });
    }
    const uploadLogo=()=>{
        Toast.loading({
            forbidClick: true,
            message: "处理中......",
        });
        memberApi.uploadLogo({url:paramsUpdate.logo}).then(res=>{
            Toast.clear();
            if(res.status==200){
                getInfo(); 
            }
        });
    }
    return {
        options,
        paramsuPw,
        paramsUpdate,
        getInfo,
        logout,
        updatePwd,
        isLogin,
        updateNickName,
        updatePhone,
        updateLogo,
        uploadLogo
    }
}

export default memberModel