<template>
  <div class="headerbox">
    <div class="header">
      <div class="headerL">
        <a onclick="javascript:history.back(-1)" class="goback"
          ><img src="images/goback.png"
        /></a>
      </div>
      <div class="headerC">
        <p>{{ options.title }}</p>
      </div>
      <div class="headerR"></div>
    </div>
  </div>
  <div class="clear"></div>
  <div class="hbox"></div>
  <div class="kbox"></div>
  <div class="paybox">
    <div class="pay3" v-if="options.type != 'CONTINUITY'" @click="setChannel('balance_pay')">
      <div class="pay3_L"><img src="images/pay3.png" /><span>余额</span></div>
      <div class="pay3_R">
        <div
          :class="
            options.payForm.channel == 'balance_pay'
              ? 'gwccheck on'
              : 'gwccheck'
          "
        ></div>
      </div>
      <div class="pay3_C">
        可用金额{{ stringUtils.fixedFormat(options.payer.acctBalance) }}元
      </div>
    </div>
    <div class="pay3" @click="setChannel('fast_pay')">
      <div class="pay3_L">
        <img src="images/pay4.png" /><span>快捷支付</span>
      </div>
      <div class="pay3_R">
        <div
          :class="
            options.payForm.channel == 'fast_pay' ? 'gwccheck on' : 'gwccheck'
          "
        ></div>
      </div>
    </div>
    <!-- <div class="pay3" @click="setChannel('union_wap')">
          <div class="pay3_L">
            <img src="/fund/home/images/pay5.png" /><span>银联云闪支付</span>
          </div>
          <div class="pay3_R">
            <div
              :class="
                options.payForm.channel == 'union_wap' ? 'gwccheck on' : 'gwccheck'
              "
            ></div>
          </div>
        </div> -->
    <div class="pay3 wx" @click="setChannel('wx_pub')">
      <div class="pay3_L">
        <img src="images/pay6.png" /><span>微信支付</span>
      </div>
      <div class="pay3_R">
        <div
          :class="
            options.payForm.channel == 'wx_pub' ? 'gwccheck on' : 'gwccheck'
          "
        ></div>
      </div>
      <div class="tuijian">
        <img src="images/jian.png" />
      </div>
    </div>
  </div>
  <div class="fbox2"></div>
  <div class="hejiBox jiesuan">
    <div class="heji">
      <div class="heji_3">
        <p>
          支付：<span
            >￥{{
              stringUtils.fixedFormat(
                options.item.preferentialAmount + options.item.mailPrice
              )
            }}</span
          >
        </p>
      </div>
      <div class="heji_5" v-if="options.isHandle">
        <a href="javascript:;" >付款中</a>
      </div>
      <div class="heji_5" v-else>
        <a href="javascript:;" @click="pay">付款</a>
      </div>
    </div>
  </div>
  <van-popup
    v-model:show="options.isAddShow"
    round
    position="center"
    :close-on-click-overlay="false"
    z-index:10000
    :style="{ height: '42%', width: '90%' }"
  >
    <div id="size" style="height: 0.3rem">
      <div class="size1">
        <div class="size1_3" @click="options.isAddShow = false">
          <img src="images/close.png" />
        </div>
      </div>
    </div>
    <van-divider>快捷银行卡绑定</van-divider>
    <div class="addressDiv">
      <div class="addiv1" @click="bankOptions.isShow = true">
        <div class="addiv1_l">开户银行：</div>
        <div class="addiv1_r">
          {{ options.cardFrom.bankName }}
        </div>
      </div>
      <div class="addiv1" @click="options.isPhoneShow = true">
        <div class="addiv1_l">预留手机：</div>
        <div class="addiv1_r">
          <!-- <input v-model="options.cardFrom.cardTelNo" maxlength="11" type="text" /> -->
          {{ options.cardFrom.cardTelNo }}
        </div>
      </div>
      <!-- <div class="addiv1" @click="options.isCityShow = true">
          <div class="addiv1_l">省市区：</div>
          <div class="addiv1_r">
            {{ options.item.province == "" ? "" : options.item.province + "/"
            }}{{ options.item.city == "" ? "" : options.item.city + "/"
            }}{{ options.item.region }}
          </div>
        </div> -->
      <div class="addiv1" @click="options.iscardIdShow = true">
        <div class="addiv1_l">银行卡号：</div>
        <div class="addiv1_r">
          <!-- <input v-model="options.cardFrom.cardId" maxlength="19" type="text" /> -->
          {{ options.cardFrom.cardId }}
        </div>
      </div>
    </div>
    <div class="size3">
      <a href="javascript:;" @click="binding">绑定</a>
    </div>
  </van-popup>

  <van-popup
    v-model:show="options.isShow"
    position="bottom"
    round
    :style="{ height: '50vh', width: '90%' }"
  >
    <div class="sx_1">
      银行卡<img @click="options.isShow = false" src="images/close.png" />
    </div>
    <div
      :class="
        a.tokenNo == options.payForm.channelMemberId
          ? 'addressbox on'
          : 'addressbox'
      "
      style="width: 100%; margin-top: 1rem"
      v-for="a in options.cards"
      :key="a.id"
    >
      <div class="addressbox_2" style="float: left; width: 10%">
        <div class="ad1">
          <div class="ad1_1" @click="setCard(a.tokenNo)">
            <div
              :class="options.cardId != a.id ? 'gwccheck on' : 'gwccheck'"
            ></div>
          </div>
        </div>
      </div>
      <div
        class="addressbox_1"
        style="float: left; width: 88%; text-align: left"
      >
        <p class="p1">卡号：{{ a.cardId }}</p>
      </div>
      <div class="clear"></div>
    </div>
    <div class="sx_3">
      <a href="javascript:;">添加</a>
    </div>
  </van-popup>
  <van-popup v-model:show="bankOptions.isShow" round position="bottom">
    <van-cascader
      v-model="bankOptions.cascaderValue"
      title="请选择开户银行"
      :options="bankOptions.banks"
      @close="bankOptions.isShow = false"
      @finish="onBankFinish"
    />
  </van-popup>
  <van-popup v-model:show="options.isCityShow" round position="bottom">
    <van-cascader
      v-model="options.cascaderValue"
      title="请选择所在地区"
      :options="options.citys"
      @close="options.isCityShow = false"
      @finish="onFinish"
    />
  </van-popup>
  <van-popup v-model:show="options.isPhoneShow" position="bottom">
    <van-number-keyboard
      v-if="options.isPhoneShow"
      v-model="options.cardFrom.cardTelNo"
      theme="custom"
      :z-index="2010"
      close-button-text="完成"
      :show="options.isPhoneShow"
      @blur="options.isPhoneShow = false"
    />
  </van-popup>
  <van-popup v-model:show="options.iscardIdShow" position="bottom">
    <van-number-keyboard
      v-if="options.iscardIdShow"
      v-model="options.cardFrom.cardId"
      theme="custom"
      :z-index="2010"
      close-button-text="完成"
      :show="options.iscardIdShow"
      @blur="options.iscardIdShow = false"
    />
  </van-popup>
  <van-popup
    v-model:show="options.isSmsshow"
    position="center"
    round
    :close-on-click-overlay="false"
    :style="{ height: '20vh', width: '90%' }"
  >
    <div class="sx_1" style="text-align: center; margin-bottom: 0.2rem">
      快捷银行卡绑定短信验证
    </div>
    <van-field
      v-model="options.smsFrom.code"
      label="验证码"
      placeholder="请输入快捷银行卡短信验证码"
    />
    <div class="size3">
      <a href="javascript:;" @click="confirmCard">验证</a>
    </div>
  </van-popup>
  <van-popup
    v-model:show="options.isFastConfirm"
    position="center"
    round
    :close-on-click-overlay="false"
    :style="{ height: '20vh', width: '90%' }"
  >
    <div class="sx_1" style="text-align: center; margin-bottom: 0.5rem">
      快捷支付短信验证
    </div>
    <van-cell-group inset>
      <van-field
        v-model="options.fastForm.code"
        center
        clearable
        placeholder="请输入快捷支付短信验证码"
      >
        <template #button>
          <!-- <van-button size="mini" round type="primary" style="line-height: 2rem; font-size: 0.55rem;">重新获取</van-button> -->
          <check-button
            v-model:isSend="options.isRegSend"
            :timeout="60"
            :textName="'重新获取'"
            @toggleSend="reSms"
          ></check-button>
        </template>
      </van-field>
    </van-cell-group>
    <div class="size3">
      <a href="javascript:;" @click="fastConfirm">验证</a>
    </div>
  </van-popup>
  <van-dialog />
</template>
    
    <script>
import { reactive, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import orderApi from "@/api/orderApi";
import fundApi from "@/api/fundApi";
import snapApi from "@/api/snapApi";
import config from "@/config/configUtils";
import auctionApi from "@/api/auctionApi";
import { Toast } from "vant";
import cookies from "js-cookie";
import citysData from "@/json/AdapayProvince.json";
import bankModel from "@/model/bankModel";
import stringUtils from "@/utils/stringUtils";
import CheckButton from "@/components/CheckButton.vue";
export default {
  setup() {
    const route = useRoute();
    const router = useRouter();
    console.log();
    const options = reactive({
      doId: route.params.id,
      type: route.params.type,
      item: {
        orderAddress: null,
        preferentialAmount: 0,
        mailPrice: 0,
        status: 0,
      },
      payForm: {
        source: route.params.type,
        sourceId: route.params.id,
        channelMemberId: "",
        channel: "wx_pub",
      },
      fastForm: {
        paymentId: "",
        code: "",
      },
      cardFrom: {
        id: "",
        cardId: "",
        cardTelNo: "",
        vipCode: "",
        expiration: "",
        bankName: "",
        bankCode: "",
        provCode: "",
        areaCode: "",
        provName: "",
      },
      smsFrom: {
        id: "",
        code: "",
      },
      payer: {
        acctBalance: 0,
      },
      cardId: "",
      citys: [],
      cards: [],
      isShow: false,
      isAddShow: false,
      isCityShow: false,
      isSmsshow: false,
      isPhoneShow: false,
      iscardIdShow: false,
      isFastConfirm: false,
      isRegSend: false,
      title: "收银台",
      isHandle:false
    });
    const store = useStore();
    onMounted(() => {
      options.citys = citysData;
    });
    const { bankOptions } = bankModel();
    const get = async () => {
      if (options.type == "ORDER") {
        options.title = "收银台";
        await orderApi.get({ orderId: options.doId }).then((res) => {
          if (res.status == 200) {
            var data = res.result;
            options.item = {
              preferentialAmount: data.preferentialAmount,
              mailPrice: data.mailPrice,
              status: 0,
            };
          }
        });
      } else if (options.type == "CONTINUITY") {
        options.title = "收银台";
        await snapApi.getPay({ id: options.doId }).then((res) => {
          if (res.status == 200) {
            var data = res.result;
            options.item = {
              preferentialAmount: data.payAmount,
              mailPrice: 0,
              status: data.status,
            };
            if (options.item.status == 1) {
              router.push({ path: "/snapPayList" });
            }
          }
        });
      } else if (options.type == "BOND" || options.type == "MBOND") {
        options.title = "支付保证金";
        await auctionApi.getBond({ auctionId: options.doId }).then((res) => {
          if (res.status == 200) {
            var data = res.result;
            if (data.isAvailable == 0) {
              Toast.fail("亲，不可支付保证金拍卖未设置");
            } else {
              options.item = {
                preferentialAmount: data.amount,
                mailPrice: 0,
                status: data.isBond,
              };
              options.payForm.source = "BOND";
            }
          }
        });
      }
      getCards();
      getAuth();
    };
    const getAuth = () => {
      fundApi.getAuth().then((res) => {
        if (res.status == 200) {
          if (res.result != null) {
            options.payer = res.result;
          } else {
            stringUtils.redirectForm(config.authUrl, [], "post");
          }
        } else {
          Toast.fail(res.message);
        }
      });
    };
    const onBankFinish = ({ selectedOptions }) => {
      console.log("++++++++++++++++++++++++++++++++++");
      bankOptions.isShow = false;
      options.cardFrom.bankName = selectedOptions
        .map((option) => option.text)
        .join("");
      var fieldValue = selectedOptions.map((option) => option.value);
      options.cardFrom.bankCode = fieldValue[0];
      console.log(options.cardFrom.bankName);
    };
    const onFinish = ({ selectedOptions }) => {
      options.isCityShow = false;
      options.cardFrom.provName = selectedOptions
        .map((option) => option.text)
        .join("/");
      var fieldValue = selectedOptions.map((option) => option.value);
      options.cardFrom.provCode = fieldValue[0];
      options.cardFrom.areaCode = fieldValue[1];
      console.log(fieldValue);
    };
    const setCard = (card) => {
      options.payForm.channelMemberId = card.tokenNo;
      options.cardId = card.cardId;
    };
    const confirmCard = () => {
      if (options.smsFrom.code === "") {
        options.isSmsshow = true;
        Toast.fail("亲，请输入快捷银行卡短信验证");
        return;
      }
      fundApi.confirmCards(options.smsFrom).then((res) => {
        if (res.status == 200) {
          options.isSmsshow = false;
        }
      });
    };
    const createCard = () => {
      fundApi.createCards(options.cardFrom).then((res) => {
        if (res.status == 200) {
          options.isSmsshow = true;
        }
      });
    };
    const getCards = () => {
      options.cards = [];
      fundApi.getCards().then((res) => {
        if (res.status == 200) {
          options.cards = res.result;
        }
      });
    };
    const setChannel = (channel) => {
      if (channel == "fast_pay") {
        if (options.cards.length == 0) {
          options.isAddShow = true;
        }
        if (options.cards.length == 1) {
          options.payForm.channelMemberId = options.cards[0].tokenNo;
        } else {
          options.isShow = true;
        }
      } else if (channel == "balance_pay") {
        if (
          parseFloat(options.payer.acctBalance) <
          options.item.preferentialAmount
        ) {
          channel = "wx_pub";
          Toast.fail("亲，您的余额不足！");
        }
      }
      options.payForm.channel = channel;
    };
    const binding = () => {
      if (options.cardFrom.cardId == "") {
        Toast.fail("请输入银行卡卡号！");
        return;
      }
      if (options.cardFrom.cardTelNo == "") {
        Toast.fail("请输入银行预留手机号！");
        return;
      }
      if (options.cardFrom.bankCode == "") {
        Toast.fail("请输入开户银行！");
        return;
      }
      if (!/^1[3|4|5|7|8]\d{9}$/.test(options.cardFrom.cardTelNo)) {
        Toast.fail("银行预留手机号格式不正确！");
        return;
      }
      fundApi.createCards(options.cardFrom).then((res) => {
        options.isAddShow = false;
        if (res.status == 200) {
          options.smsFrom.id = res.token;
          console.log(options.smsFrom.id);
          getCards();
          options.isSmsshow = true;
        } else {
          Toast.fail(res.message);
        }
      });
    };
    const fastConfirm = () => {
      fundApi.fastConfirm(options.fastForm).then((res) => {
        if (res.status == 200) {
          options.isFastConfirm = false;
          to();
        } else {
          Toast.fail(res.message);
        }
      });
    };
    const reSms = () => {
      fundApi
        .fastSmscode({ paymentId: options.fastForm.paymentId })
        .then((res) => {
          if (res.status == 200) {
            options.isRegSend = true;
          }
        });
    };
    const pay = () => {
      options.isHandle=true;
      if (options.payForm.channel == "wx_pub") {
        let ua = navigator.userAgent.toLowerCase();
        if (ua.match(/MicroMessenger/i) == "micromessenger") {
          console.log("微信环境中");
          options.payForm.channelMemberId = cookies.get("openid");
        } else {
          console.log("其他环境中（例如浏览器等等）");
          Toast.fail("无法支付！");
          options.isHandle=false;
          return;
        }
      }
      Toast.loading({
        forbidClick: true,
        message: "支付中......",
      });
      fundApi.pay(options.payForm).then((res) => {
        Toast.clear();
        options.isHandle=false;
        if (res.status == 200) {
          //alert(JSON.parse(res.result));
          if (options.payForm.channel == "wx_pub") {
            window.WeixinJSBridge.invoke(
              "getBrandWCPayRequest",
              res.result,
              function (res1) {
                if (res1.err_msg == "get_brand_wcpay_request:ok") {
                  to();
                } else if (res1.err_msg != "get_brand_wcpay_request:cancel") {
                  Toast.fail("不在微信中无法支付");
                }
              }
            );
          } else if (options.payForm.channel == "fast_pay") {
            var data = res.result;
            options.fastForm.paymentId = data.channelPayId;
            options.isFastConfirm = true;
          }else if (options.payForm.channel == "balance_pay") {
            to();
          }
        }else if(res.status==401){
          stringUtils.redirectForm(config.authUrl, [], "post");
        }
        else if(res.status==306){
          Toast.fail(res.message);
          setTimeout(()=>{
            to();
          },1000)
        }else{
          Toast.fail(res.message);
        }
        
      });
    };
    const to = () => {
      if (options.type == "ORDER") {
        router.push({ path:"/orderDetail/" + options.doId});
      } else if (options.type == "BOND"||options.type == "MBOND") {
        router.push({ path:"/myBond/"});
      } else {
        //router.push({ path:"/snapPayList"});
        store.commit('setNoticeMessage', { isNoticeShow: true, close: false, title: "连续拍", type: 'SNAP', message: '支付完成，数据处理中......' })
      }
    };
    get();
    return {
      options,
      get,
      pay,
      setChannel,
      setCard,
      bankOptions,
      onBankFinish,
      onFinish,
      createCard,
      confirmCard,
      getAuth,
      binding,
      fastConfirm,
      reSms,
    };
  },
  components: { CheckButton },
};
</script>
    
<style>
.sx_1 img {
  float: right;
  width: 1rem;
  margin-top: 0.5rem;
}
.yzm-hq {
  background-color: #07c160;
  color: #fff;
  border-color: #07c160;
  font-size: 0.75rem;
  text-align: center;
  border-radius: 0.5rem;
  width: 6rem;
}
</style>