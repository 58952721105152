<template>
    <div class="headerbox">
      <div class="header">
        <div class="headerL">
          <a onclick="javascript:history.back(-1)" class="goback"
            ><img src="images/goback.png"
          /></a>
        </div>
        <div class="headerC">
          <p>我的红包</p>
        </div>
        <div class="headerR"></div>
      </div>
    </div>
    <div class="clear"></div>
    <div class="hbox"></div>
    <div class="jfheader">
      <div class="guize">红包规则</div>
      <div class="jfsub">红包进入余额，可支付货款和提现</div>
      <div class="jfgl">
        
      </div>
    </div>
    <div class="clear"></div>
    <div class="kbox"></div>
    <div class="jfbox">
      <van-pull-refresh v-model="options.refreshing" @refresh="onRefresh">
      <van-list
        v-model:loading="options.loading"
        :finished="options.finished"
        :offset="1000"
        finished-text="没有更多了"
        @load="onLoad"
      >
      <div class="jfbox1" v-for="b in options.data" :key="b">
        <div class="jfbox1_L">
          <img :src="b.pictureSmall" style="object-fit: cover" />
        </div>
        <div class="jfbox1_R">
          <div class="jfbox1_R1">
            <div class="v1">{{b.title}}</div>
            <div class="v2" style="color:#ff8100;" v-if="b.div_status==0">
                拍卖交易中
            </div>
            <div class="v2" style="color:#ad0;" v-else-if="b.div_status==1">
                已获得
            </div>
            <div class="v2" style="color:#ff000c;" v-else-if="b.div_status==2">
                拍卖交易失败
            </div>
          </div>
          <div class="jfbox1_R2">
            <div class="v3">红包金额</div>
            <div class="v4">￥{{stringUtils.fixedFormat(b.amount)}}</div>
          </div>
        </div>
        <div class="clear"></div>
      </div>
      </van-list>
      </van-pull-refresh>
    </div>
  </template>
  
  <script>
  import { reactive } from "@vue/reactivity";
  import auctionApi from "@/api/auctionApi";
  export default {
    setup() {
      const options = reactive({
        data: [],
        pageCount: 1,
        pageNo:1,
        refreshing: false,
        loading: false,
        finished: false,
        total: 0,
        searchParams: {
          draw: 1,
          start: 0,
          length: 10,
          type: 0,
        },
      });
      const onRefresh= async ()=>{
        options.finished = false;
        options.loading = true;
        options.refreshing = true;
        onLoad();
      }
      const onLoad = async () => {
        if (options.refreshing) {
        options.data = [];
        options.pageCount = 1;
        options.pageNo=1;
        options.refreshing = false;
      }else{
        if (options.pageNo < options.pageCount) {
          options.pageNo = options.pageNo + 1;
        }
      }
      options.searchParams.start = (options.pageNo - 1) * options.searchParams.length;
      console.log("start1:" + options.searchParams.start);
      options.searchParams.start = options.searchParams.start < 0 ? 0 : options.searchParams.start;
      if (
        options.data.length > 0 &&
        options.total > 0 &&
        options.data.length >= options.total
      ) {
        options.finished = true;
        return;
      }
         await auctionApi.getMyRedPacket(options.searchParams).then((res) => {
          if (res.status == 200) {
          options.total = res.recordsTotal;
          let count = Math.ceil(res.recordsTotal / options.searchParams.length);
          options.pageCount = count;
          res.data.forEach((item) => {
            if (options.data.length < res.recordsTotal) {
              options.data.push(item);
            }
          });
        }
          options.loading = false;
          if (options.data.length >= options.total) {
            options.finished = true;
          }
        });
      };
      const setType=(type)=>{
          options.searchParams.type=type;
          onRefresh();
      }
      onRefresh();
      return {
        options,
        setType,
        onLoad,
        onRefresh
      };
    },
  };
  </script>
  
  <style>
  .jfbox1_R1 .but {
      display: block;
      border: 1px solid #ff2150;
      color: #fff;
      font-size: 0.75rem;
      padding: 0 0.5rem;
      height: 1.4rem;
      line-height: 1.4rem;
      border-radius: 3px;
      background: #ff2150;
      box-sizing: border-box;
  }
  .jfgl .st a{
      color:#e3d528 !important;
  }
  </style>